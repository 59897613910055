<template>
  <div class="question">

    <ButtonComponent
      class="prev"
      @click.prevent="prev()"
    >Retour</ButtonComponent>

    <ButtonComponent
      class="next"
      @click.prevent="next()"
    >Continuer</ButtonComponent>

    <div class="question_head">
      <h2 class="question_title">
        {{pageIndex}}/{{questionsTotal - 1}}
        <span>{{question.question}}</span>
      </h2>
      <div class="question_info info">
        <span>i</span>
        <div class="question_modal modal">
          <div class="modal_inner">
            <p v-html="question.information"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="question_body">
      <template v-if="question.type === 'radio' || question.type === 'checkbox'">
        <div class="question_list-row">
          <label
            class="list_item"
            v-bind:key="keyReponse"
            v-for="(response, keyReponse) in question.response"
          >
            <input
              class="text"
              :type="question.type"
              :name="question.id"
              :value="keyReponse"
              :class="{ checked: response.value === formData[question.id][0].value }"
              v-model="formData[question.id]"
            />
            <span>{{response.value}}</span>
          </label>
        </div>
      </template>
  
      <template v-else-if="question.type === 'user_story'">
        <div class="question_list-column">
          <div class="list_item">
            <label for="uf1">Nom du projet</label>
            <input
              id="uf1"
              class="text"
              :type="'text'"
              :name="`${question.id}_1`"
              :value="formData[question.id].uf1"
              :placeholder="formData[question.id].uf1"
              v-model="formData[question.id].uf1"
            />
          </div>
          <div class="list_item">
            <label for="uf2">En tant que</label>
            <input
              id="uf2"
              class="text"
              :type="'text'"
              :name="`${question.id}_2`"
              :value="formData[question.id].uf2"
              :placeholder="formData[question.id].uf2"
              v-model="formData[question.id].uf2"
            />
          </div>
          <div class="list_item">
            <label for="uf3">Dans le but de</label>
            <input
              id="uf3"
              class="text"
              :type="'text'"
              :name="`${question.id}_3`"
              :value="formData[question.id].uf3"
              :placeholder="formData[question.id].uf3"
              v-model="formData[question.id].uf3"
            />
          </div>
          <div class="list_item">
            <label for="uf4">Je souhaite</label>
            <input
              id="uf4"
              class="text"
              :type="'text'"
              :name="`${question.id}_4`"
              :value="formData[question.id].uf4"
              :placeholder="formData[question.id].uf4"
              v-model="formData[question.id].uf4"
            />
          </div>
        </div>
      </template>
  
      <template v-else-if="question.type === 'range'">
        <div class="range">
          <label>
          <span>
            <span>{{formData[question.id]}} <em>{{question.response.label}}{{(formData[question.id] > 1 && question.response.label !== '%') ? 's' : ''}}</em></span>
          </span>
            <input
              type="range"
              :min="question.response.min"
              :max="question.response.max"
              v-model="formData[question.id]"
            />
          </label>
        </div>
      </template>
    </div>

    <ButtonComponent
      v-if="pageIndex === 8"
      class="submit"
      @click.prevent="submit()"
    >Voir les impacts</ButtonComponent>

    <div class="sun"></div>
  </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'QuestionComponent',
  components: { ButtonComponent },
  props: {
    pageIndex: {
      type: Number,
      default: 0
    },
    questionsTotal: {
      type: Number,
      default: 0
    },
    question: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
    submit() {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/_var';

.question {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_head {
    position: relative;
    z-index: 3;
    max-width: 500px;
    width: 100%;
    padding-right: 2rem;
  }

  &_body {
    max-width: 390px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin-top: 2rem;
  }

  &_title {
    position: relative;
    z-index: 1;
    display: flex;
    font-size: 2rem;

    span {
      margin-left: 1rem;
    }
  }

  &_info {
    position: absolute;
    top: .25rem;
    right: 0;
    z-index: 2;
  }

  &_modal {
    width: calc(100vw - 85px);
  }

  &_list-column {
    display: flex;
    flex-direction: column;
    text-align: left;

    .list_item {
      display: flex;
      padding: 1rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $dark-color;
    }

    .text {
      flex: 1;
      width: 100%;
      margin-left: .5rem;
      border-bottom: 1px solid $dark-color;
      font-size: 1.25rem;
      font-weight: bold;
      color: $dark-color;
    }
  }

  &_list-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;

    .list_item {
      overflow: hidden;
      position: relative;
      z-index: 2;
      padding: .25rem;
    }

    .text {
      position: absolute;
      top: -100%;
      left: -100%;
      opacity: 0;
    }

    .text + span {
      display: block;
      padding: 1rem 1.25rem;
      line-height: 1.1;
      text-align: center;
      background-color: transparent;
      border-radius: 1.75rem;
      border: 2px solid $dark-color;
      font-weight: bold;
      cursor: pointer;
    }

    .text:checked + span,
    .text.checked + span {
      background-color: $dark-color;
      color: $light-color;
    }
  }
}
</style>
  