<template>
  <div id="app">
    <MainComponent :questions="questions" />
  </div>
</template>

<script>
import questions from './js/questions';
import MainComponent from './components/MainComponent.vue';

export default {
  name: 'App',
  components: { MainComponent },
  data: () => {
    return {
      questions: questions
    }
  }
};
</script>
