<template>
  <form class="form" :class="{ 'resources-is-active': isResources }">
    <PageComponent
      v-for="(question, index) in questions"
      :key="index"
      :page-index="index"
      :question-index="questionIndex"
      :questions-total="questions.length"
      :question="question"
      :form-data="formData"
      :is-submit="isSubmit"
      @prev="prev()"
      @next="next()"
      @submit="submit()"
    />
    <ResultComponent
      :resulted="resulted"
      :what-the-impact="whatTheImpact"
      :isSubmit="isSubmit"
      @restart="restart()"
      @open-resources="openResources()"
    />
    <ResourcesComponent @close-resources="closeResources()" />
  </form>
</template>

<script>
import results from '../js/results';
import PageComponent from './PageComponent.vue';
import ResultComponent from './ResultComponent.vue';
import ResourcesComponent from './ResourcesComponent.vue';

class Result {
  constructor(
    id = '',
    title = '',
    icon = '',
    text = '',
    information = '',
    percent = ''
  ) {
    this.id = id;
    this.title = title;
    this.icon = icon;
    this.text = text;
    this.information = information;
    this.percent = percent;
  }
}

export default {
  name: 'FormComponent',
  components: {
    PageComponent,
    ResultComponent,
    ResourcesComponent
  },
  data: () => {
    return {
      formData: {
        q1: {
          uf1: 'site web',
          uf2: 'client',
          uf3: 'choisir une agence',
          uf4: 'lire une page de contenu'
        },
        q2: 2500,
        q3: 3,
        q4: 'fr',
        q5: 70,
        q6: 5,
        q7: 2,
        q8: 'eu'
      },
      questionIndex: 0,
      results: [],
      oldResults: [],
      whatTheImpact: {
        title: 'Voici les impacts du parcours analysé.',
        current: 0,
        tabs: [
          {
            label: 'Par utilisateur',
            text: 'Équivalences par utilisateur'
          },
          {
            label: 'Par an',
            text: 'Équivalences par année'
          },
          {
            label: 'Données brutes',
            text: 'Impacts écologiques par année'
          }
        ]
      },
      isSubmit: false,
      isResources: false
    }
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    resulted() {
      return this.results || 0;
    }
  },
  methods: {
    prev() {
      this.questionIndex--;
      if (this.questionIndex < 0) {
        this.questionIndex = this.questionsTotal - 1;
      }
    },
    next() {
      this.questionIndex++;
      if (this.questionIndex >= this.questionsTotal) {
        this.questionIndex = 0;
      }
    },
    go(index) {
      this.isSubmit = false;
      this.isResources = false;
      this.whatTheImpact.current = 0;
      this.questionIndex = index - 1;
    },
    restart() {
      this.oldResults = this.newResults.slice();
      this.isRestart = true;
      this.go(2);
    },
    openResources() {
      this.isResources = true;
    },
    closeResources() {
      this.isResources = false;
    },
    findVowel(letter) {
      const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
      for (let i = 0; i < vowels.length; i++) {
        if (letter === vowels[i]) {
          return true;
        }
      }
      return false;
    },
    submit() {
      this.results = [];
      
      const B3 = this.formData.q2;
      const B4 = this.formData.q3;
      const B5 = this.formData.q4;
      const B6 = this.formData.q5;
      const B7 = this.formData.q6;
      const B8 = this.formData.q7;
      const B9 = this.formData.q8;
      const energeticMix = {
        eu: 0.5,
        fr: 0.1,
        as: 1,
        oc: 0.8,
        na: 0.4,
        sa: 0.5,
        ww: 0.5,
      };
      const B11 = (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * 75 + (((B3 * 12 * B4) / 60) * (B6 / 100)) / (365 * 4) * 30 + (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * 30.8;
      const B12 = (((B3 * 12 * B4) / 60) / (24 * 365)) * 150;
      const B13 = (((450 * 365 * 24) / 1000) * (60 / 100)) * B8 * 1.8;
      const C11 = (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * 75 * energeticMix[B5] + (((B3 * 12 * B4) / 60) * (B6 / 100)) / (365 * 4) * 30 * energeticMix[B5] + (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * 30.8 * energeticMix[B5] + (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * (189 / 5) + (((B3 * 12 * B4) / 60) * (B6 / 100)) / (365 * 4) * (169 / 4) + (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * (265 / 6);
      const C12 = (((B3 * 12 * B4) / 60) / (24 * 365)) * 150 * energeticMix[B5] + (((B3 * 12 * B4) / 60) / (24 * 365)) * (83 / 5) + B3 * 12 * B7 * 2.1 / 1024 * (0.002);
      const C13 = (((450 * 365 * 24) / 1000) * 0.6) * B8 * 1.8 * energeticMix[B9] + B8 * (756 / 5);
      const D11 = (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * (572 / 5) + (((B3 * 12 * B4) / 60) * (B6 / 100)) / (365 * 4) * (572 / 5) + (((B3 * 12 * B4) / 60) * (1 - (B6 / 100))) / (220 * 7) * (1689 / 5);
      const D12 = (((B3 * 12 * B4) / 60) / (24 * 365)) * (425 / 5);
      const D13 = B8 * (2288 / 5);
      const E11 = (B3 * 12 * 6 * B7) / 100;
      const B14 = Math.round(B11 + B12 + B13) || 0;
      const C14 = (C11 + C12 + C13).toFixed(1) || 0;
      const D14 = ((D11 + D12 + D13) / 1000).toFixed(2) || 0;
      const E14 = Math.round(E11) || 0;
      const B15 = Math.round((B11 + B12 + B13) / 4679) || 0;
      const C15 = Math.round((C11 + C12 + C13) / 0.1) || 0;
      const D15 = Math.round((D11 + D12 + D13) / 1.4) || 0;
      const E15 = Math.round((E11) / 55) || 0;
      const B16 = Math.round(((((B11 + B12 + B13) / (B3 * 12)) * 60) / 1250) * 60 * 60) || 0;
      const C16 = Math.round((C11 + C12 + C13) / (B3 * 12) / 0.0001) || 0;
      const D16 = Math.round((D11 + D12 + D13) / (B3 * 12) / 0.0075) || 0;
      const E16 = (E11 / (B3 * 12) / 0.18).toFixed(1) || 0;

      const texts = [
        `<p>Chaque utilisateur faisait fonctionner 1 micro-ondes pendant <strong>${B16}s</strong></p>`,
        `<p>chaque utilisateur faisait <strong>${C16}m</strong> en voiture</p>`,
        `<p>Chaque utilisateur creusait pour extraire l'équivalent de <strong>${D16} pièce${(D16 > 1) ? "s" : ""}</strong> de 1€</p>`,
        `<p>Chaque utilisateur consommait l'équivalent de <strong>${E16}</strong> verre${(E16 > 1) ? "s" : ""} d'eau</p>`,
        `<p>Notre parcours consommait de l'électricité comme <strong>${B15} maison${(B15 > 1) ? "s" : ""}</strong> françaises</p>`,
        `<p>Notre parcours émettait des gaz à effet de serre comme <strong>${C15} voiture${(C15 > 1) ? "s" : ""}</strong> faisant le tour de la terre</p>`,
        `<p>Notre parcours creusait pour extraire l'équivalent de <strong>${D15} ordinateur${(D15 > 1) ? "s" : ""}</strong> portables</p>`,
        `<p>Notre parcours prenait l'équivalent de <strong>${E15} douche${(E15 > 1) ? "s" : ""}</strong></p>`,
        `<p><strong>${B14} kWh</strong></p>`,
        `<p><strong>${C14} kg</strong> CO2-eq</p>`,
        `<p><strong>${D14}</strong> Tonnes de matière 1ère</p>`,
        `<p><strong>${E14}</strong> litre${(E14 > 1) ? "s" : ""}</p>`
      ];

      this.newResults = [B16, C16, D16, E16, B15, C15, D15, E15, B14, C14, D14, E14];

      let percent = 0;
      let percents = [];

      if (this.oldResults.length > 0) {
        for (let i = 0; i < this.newResults.length; i++) {
          percent = (((this.newResults[i] - this.oldResults[i]) / this.oldResults[i]) * 100).toFixed();
          if (percent > 0) {
            percent = '+' + percent;
          }
          percents.push(percent);
        }
      }

      let result1 = [], result2 = [], result3 = [];

      for (let i = 0; i < 4; i++) {
        result1.push(new Result(i, results[i].title, results[i].icon, texts[i], results[i].information, percents[i]));
      }
      for (let i = 0; i < 4; i++) {
        result2.push(new Result(i + 4, results[i + 4].title, results[i + 4].icon, texts[i + 4], results[i + 4].information, percents[i + 4]));
      }
      for (let i = 0; i < 4; i++) {
        result3.push(new Result(i + 8, results[i + 8].title, results[i + 8].icon, texts[i + 8], results[i + 8].information, percents[i + 8]));
      }

      this.results = [result1, result2, result3];

      const str = this.formData.q1.uf3.split('');
      let _of = '';

      if (this.findVowel(str[0])) {
        _of = "d'";
      } else {
        _of = 'de';
      }

      this.whatTheImpact.title = `Voici les impacts du parcours "${this.formData.q1.uf4}", dans le but "${_of} ${this.formData.q1.uf3}".`;
      this.isSubmit = true;
    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          if (this.questionIndex > 0 && this.questionIndex < 10 && !this.isSubmit) {
            this.go(this.questionIndex + 1);
            this.questionIndex--;
          }
          break;
        case 'ArrowRight':
          if (this.questionIndex < this.questions.length - 1 && !this.isSubmit) {
            this.go(this.questionIndex + 1);
            this.questionIndex++;
          }
          break;
        case 'Enter':
          this.submit();
          break;
        case 'Escape':
          this.go(2);
          this.restart();
          break;
        default:
          return;
      }
      e.preventDefault();
  });
}
};
</script>
