<template>
  <div class="loading">
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent'
}
</script>

<style lang="scss" scoped>
.loading {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 auto;
  position: relative;
  top: 10px;
  border-radius: 50%;
  border-width: .75rem;
  border-style: solid;
  border-top-color: #00dc82;
  border-right-color: #2dcecc;
  border-bottom-color: #2dcecc;
  border-left-color: #00dc82;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
