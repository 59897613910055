<template>
  <div
    class="button"
    :class="cssClass"
    v-on="$listeners"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
};
</script>
