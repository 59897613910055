<template>
  <div class="resources">

    <div class="resources_content">

      <div class="resources_inner">
        <ul class="resources_list">
          <li><strong>Documentation générale</strong></li>
          <li>GIEC: <a href="https://comprendre-giec.fr/#lang=fr_FR" target="_blank">Synthèse officielle</a></li>
          <li>ADEME: <a href="https://datagir.ademe.fr/blog/budget-empreinte-carbone-c-est-quoi/" target="_blank">Synthèse du rapport du GIEC</a></li>
          <li>Philippe Bihouix: <a href="https://www.seuil.com/ouvrage/l-age-des-low-tech-philippe-bihouix/9782021160727" target="_blank">L’âge des Low Tech</a></li>
          <li>IPCC: <a href="https://interactive-atlas.ipcc.ch/" target="_blank">Interactive Atlas</a></li>
          <li>The Shift Project: <a href="https://decarbonizeurope.org/" target="_blank">Decarbonize Europe</a></li>
          <li>ADEME: <a href="https://datagir.ademe.fr/" target="_blank">Datagir Tools</a></li>
          <li>Ecoconception: <a href="https://www.eco-conception.fr/static/leco-conception-pour-tous.html">Les différents niveaux</a></li>
          <li>ADEME: <a href="https://librairie.ademe.fr/" target="_blank">Librairie</a></li>
          <li>Arte: <a href="https://www.youtube.com/watch?v=6YMRB4bgQ-o&list=PLCwXWOyIR22vvG5WLClozLrTrY0mqVBvf&ab_channel=ARTE" target="_blank">Data Science</a></li>
          <li>BD: <a href="https://www.bedetheque.com/BD-Monde-sans-fin-434522.html" target="_blank">Le monde sans fin</a></li>
        </ul>
        <ul class="resources_list">
          <li><strong>Impacts du numérique</strong></li>
          <li>GreenIT: <a href="https://www.greenit.fr/wp-content/uploads/2019/10/2019-10-GREENIT-etude_EENM-rapport-accessible.VF_.pdf" target="_blank">Empreinte environnementale du numérique mondial</a></li>
          <li>Frédéric Bordage: <a href="https://www.eyrolles.com/Informatique/Livre/ecoconception-web-les-115-bonnes-pratiques-9782212678062/" target="_blank">Ecoconception Web</a></li>
          <li>GreenIT: <a href="https://www.greenit.fr/2020/11/30/numerique-responsable-leurope-vote-une-resolution-historique/" target="_blank">Evolution de la législation Européenne</a></li>
          <li>Transition Network: <a href="https://transitionnetwork.org/news/how-much-energy-do-we-use-on-the-web/" target="_blank">How much Energy do we use on the Web</a></li>
          <li>GreenIT: <a href="https://www.greenit.fr/tag/ecoconception/" target="_blank">News sur l’écoconception</a></li>
          <li>Nega Octet: <a href="https://negaoctet.org/" target="_blank">Initiative</a></li>
        </ul>
        <ul class="resources_list">
          <li><strong>Outils d’audit</strong></li>
          <li>GreenIT Analysis: <a href="https://chrome.google.com/webstore/detail/greenit-analysis/mofbfhffeklkbebfclfaiifefjflcpad" target="_blank">Extension Chrome</a></li>
          <li>ADEME: <a href="https://www.bilans-ges.ademe.fr/documentation/UPLOAD_DOC_FR/index.htm?ordinateurs_et_equuipements_pe.htm" target="_blank">Données publiques d’ACV par équipement</a></li>
          <li>GreenIT: <a href="https://collectif.greenit.fr/ecoconception-web/115-bonnes-pratiques-eco-conception_web.html" target="_blank">115 bonnes pratiques format Web</a></li>
          <li>GreenIT: <a href="https://collectif.greenit.fr/ecoconception-web/" target="_blank">115 bonnes pratiques format ready-to-user</a></li>
          <li>ADEME: <a href="https://monconvertisseurco2.fr/" target="_blank">Convertisseur CO2</a></li>
          <li>Nega Octet: <a href="https://negaoctet.org/#Donnees" target="_blank">Base de donnée</a></li>
        </ul>
      </div>
    </div>

    <ButtonComponent
      class="close"
      @click.prevent="closeResources()"
    >Fermer</ButtonComponent>

  </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'ResourcesComponent',
  components: { ButtonComponent },
  methods: {
    closeResources() {
      this.$emit('close-resources');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/_var';

.resources {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 2;
  display: flex;
  background-color: $primary-dark-color;

  .resources-is-active & {
    left: 0;
  }

  &_content {
    position: relative;
    z-index: 2;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: $mobile) {
      display: block;
      padding: 1rem 1rem 6rem;
    }
  }

  &_inner {
    display: flex;

    @media screen and (max-width: $mobile) {
      flex-flow: row wrap;
    }
  }

  &_list {
    max-width: 380px;
    width: 100%;
    padding: 1rem;
    list-style: none;
    opacity: 0;
    transform: translateY(-20px);
    transition-property: opacity, transform;
    transition-duration: 0s;
    transition-timing-function: $easing-2;
    transition-delay: 0s;

    .resources-is-active & {
      opacity: 1;
      transform: translateY(0px);
      transition-duration: 2s, 1s;

      &:nth-child(1) {
        transition-delay: .1s;
      }

      &:nth-child(2) {
        transition-delay: .2s;
      }

      &:nth-child(3) {
        transition-delay: .3s;
      }
    }

    li {
      margin-bottom: 1rem;
    }

    strong {
      font-size: 1.5rem;
    }

    a {
      color: $dark-color;
    }
  }
}
</style>
