<template>
  <div class="main">
    <LoaderComponent v-if="loading" />
    <FormComponent
      v-else
      :questions="questions"
    />
  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import FormComponent from './FormComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    LoaderComponent,
    FormComponent
},
  data: () => {
    return {
      loading: true
    };
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
.main {
  position: relative;
}
</style>
