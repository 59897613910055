<template>
  <div
    class="page"
    :class="{
      active: pageIndex === questionIndex && !isSubmit,
      previous: pageIndex <= questionIndex - 1 || isSubmit
    }"
  >
    <div class="inner">
      <div class="cover">
        <IntroductionComponent
          v-if="question.type === 'introduction'"
          @next="next()"
        />
        <QuestionComponent
          v-else
          :page-index="pageIndex"
          :questions-total="questionsTotal"
          :question="question"
          :form-data="formData"
          @prev="prev()"
          @next="next()"
          @submit="submit()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IntroductionComponent from './IntroductionComponent.vue';
import QuestionComponent from './QuestionComponent.vue';

export default {
  name: 'PageComponent',
  components: {
    IntroductionComponent,
    QuestionComponent
  },
  props: {
    pageIndex: {
      type: Number,
      default: 0
    },
    questionIndex: {
      type: Number,
      default: 0
    },
    questionsTotal: {
      type: Number,
      default: 0
    },
    question: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    isSubmit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
    submit() {
      this.$emit('submit');
    }
  }
}
</script>
