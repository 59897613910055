<template>
  <div class="introduction">
    <h1 class="introduction_title">
      b-green
      <span>par Bewizyu</span>
    </h1>

    <ButtonComponent
      class="start"
      @click.prevent="next"
    >Démarrer</ButtonComponent>

    <div class="sun"></div>
  </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'IntroductionComponent',
  components: { ButtonComponent },
  methods: {
    next() {
      this.$emit('next');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/_var';

.introduction {
  display: flex;

  &_title {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 6rem;

    span {
      display: block;
      padding-right: 1rem;
      text-align: right;
      font-size: 1rem;
      font-weight: normal;
    }
  }
}
</style>
