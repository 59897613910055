class Question {
  constructor(
    id = '',
    type = '',
    question = '',
    response = {},
    information = '',
    help = ''
  ) {
    this.id = id;
    this.type = type;
    this.question = question;
    this.response = response;
    this.information = information;
    this.help = help;
  }
}

const questions = [
  new Question(
    'q0',
    'introduction',
    'Calcul des impacts environnementaux',
    {},
    '',
    ''
  ),
  new Question(
    'q1',
    'user_story',
    'De quel parcours mesure-t-on l\'impact ?',
    {},
    'L\'écoconception part toujours d\'un besoin utilisateur clairement <strong>identifié</strong> et dont le service numérique cherche à apporter une <strong>solution</strong>.',
    'Lorem Ipsum'
  ),
  new Question(
    'q2',
    'range',
    'Nombre de visiteurs par mois ?',
    { min: 1000, max: 10000, label: 'visiteur' },
    'Le nombre de visiteurs par mois, ainsi que le temps qu\'il passent sur votre service numérique, permet de connaître la quantité de <strong>teminaux utilisateur, équipements réseau, serveur</strong> nécessaire pour servir, transiter et consulter les contenus ou les services que vous délivrez. La compatibilité du lecteur d\'écran est activée.',
    'Si vous ne la connaissez pas, vous pouvez trouver cette info dans votre outil Google Analytics ou équivalent.'
  ),
  new Question(
    'q3',
    'range',
    'Temps passé en moyenne par utilisateur par parcours ?',
    { min: 1, max: 20, label: 'minute' },
    'Le nombre de visiteurs par mois, ainsi que le temps qu\'il passent sur votre service numérique, permet de connaître la quantité de teminaux <strong>utilisateur, équipements réseau, serveur</strong> nécessaire pour servir, transiter et consulter les contenus ou les services que vous délivrez. La compatibilité du lecteur d\'écran est activée.',
    'Lorem Ipsum'
  ),
  new Question(
    'q4',
    'radio',
    'Où se situent la majeure partie de vos utilisateurs ?',
    {
      fr: { value: 'France' },
      eu: { value: 'Europe' },
      na: { value: 'Amérique du nord' },
      sa: { value: 'Amérique du sud' },
      as: { value: 'Asie' },
      oc: { value: 'Océanie' },
      ww: { value: 'Worldwide '}
    },
    'Le mix énergétique du pays dans lequel se situent vos utilisateurs fait varier l\'<strong>impact écologique</strong>.',
    'Lorem Ipsum'
  ),
  new Question(
    'q5',
    'range',
    'Proportion d\'utilisateurs en mobile ?',
    { min: 0, max: 100, label: '%' },
    'Le parcours utilisateur, après l\'identification du besoin utilisateurs, est le deuxième point crutial en éco-conception. Plus le parcours, entre l\'utilisateur et le contenu ou le service pour lequel il est venu, est plus faible réduit l\'<strong>impact écologique</strong>. En 2021, le poids moyen d\'une page web est de <strong>2,1Mo</strong> (contre 0,013Mo en 1995).',
    'Lorem Ipsum'
  ),
  new Question(
    'q6',
    'range',
    'Nombre de pages du parcours depuis le moteur de reherche jusqu\'à la dernière page consultée ?',
    { min: 1, max: 10, label: 'page' },
    'Les conditions d\'utilisation de votre service numérique, en mobilité ou au bureau, ont des impacts bien différents.',
    'Lorem Ipsum'
  ),
  new Question(
    'q7',
    'range',
    'Nombre de serveurs ?',
    { min: 1, max: 5, label: 'serveur' },
    'Calculé à partir d\'un PUE (Power Usage Effectiveness) moyen d\'un serveur (soit 1,8). Si vous avez opté pour un hébergement "green", vous serez <strong>meilleur que la moyenne du marché</strong> sur ce paramètre.',
    'Compter les serveurs de production, staging, review, CDN, etc…'
  ),
  new Question(
    'q8',
    'radio',
    'Où sont localisés vos serveurs ?',
    {
      fr: { value: 'France' },
      eu: { value: 'Europe' },
      na: { value: 'Amérique du nord' },
      sa: { value: 'Amérique du sud' },
      as: { value: 'Asie' },
      oc: { value: 'Océanie' },
      ww: { value: 'Worldwide '}
    },
    'Le mix énergétique du pays dans lequel se situent les serveurs fait varier l\'<strong>impact écologique</strong>.',
    'Lorem Ipsum'
  ),
]

export default questions;
