<template>
  <div
    class="page"
    :class="{ active: isSubmit }"
  >
    <div class="inner">
      <div class="cover">

        <div v-if="resulted.length" class="result">
          <h3 class="result_subtitle" v-html="whatTheImpact.title"></h3>
          <ul class="result_menu">
            <li
              v-for="(tab, index) in whatTheImpact.tabs"
              :key="index"
            >
              <span
                @click="display(index)"
                :class="{ active: whatTheImpact.current === index }"
              >
                {{tab.label}}
              </span>
            </li>
          </ul>
          <div
            v-for="(tab, index) in whatTheImpact.tabs"
            :key="index"
            v-show="whatTheImpact.current === index"
            class="result_text"
          >
            <p>{{tab.text}}</p>
          </div>

          <transition-group name="cards" tag="div" class="cards">
            <div
              v-for="result in resulted[whatTheImpact.current]"
              :key="result.id"
              class="card"
            >
              <h4 class="card_title">{{result.title}}</h4>
              <div class="card_equiv">
                <div v-html="result.icon"></div>
                <div v-html="result.text"></div>
              </div>
              <div
                class="card_percent"
                :class="{ 'positive': result.percent < 0, 'negative': result.percent > 0 }"
                v-show="result.percent"
              >{{result.percent}}%</div>
              <div class="card_info info">
                <span>i</span>
                <div class="card_modal modal">
                  <div  class="modal_inner">
                    <p v-html="result.information"></p>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>

          <ButtonComponent
            class="restart"
            @click.prevent="restart()"
          >Recommencer</ButtonComponent>
          
          <div class="buttons">
            <ButtonComponent
              class="open"
              @click.prevent="openResources()"
            >Sources</ButtonComponent>
  
            <ButtonComponent
              class="download"
              @click.prevent="downloadPdf()"
            >Télécharger</ButtonComponent>
          </div>
        </div>

        <div v-else>Pas de résultat</div>

        <div class="sun"></div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake.min';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ButtonComponent from './ButtonComponent.vue';

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'ResultComponent',
  components: { ButtonComponent },
  data: () => {
    return {
      docDefinition: {}
    }
  },
  props: {
    resulted: {
      type: Array,
      default: () => []
    },
    whatTheImpact: {
      type: Object,
      default: () => {}
    },
    isSubmit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    display(index) {
      return this.whatTheImpact.current = index;
    },
    restart() {
      this.$emit('restart');
    },
    openResources() {
      this.$emit('open-resources');
    },
    stripHtml(html) {
      let tmp = document.createElement('div')
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    randomNumber(length) {
      return Math.floor(Math.random() * length);
    },
    generateId(length) {
      const posibitily = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let id = '';
      for(let i = 0; i < length; i++) {
        id += posibitily.charAt(this.randomNumber(posibitily.length));
      }
      return id;
    },
    generatePdf() {
      this.content = [];

      this.content.push({
        text: this.whatTheImpact.title,
        style: 'title'
      });

      this.content.push({
        text: this.whatTheImpact.tabs[0],
        style: 'subtitle',
      });

      this.resulted[0].forEach(result => {
        this.content.push({ text: result.title + ' ' + this.stripHtml(result.text) + '.', style: 'text' });
      });

      this.content.push({
        text: this.whatTheImpact.tabs[1],
        style: 'subtitle',
      });

      this.resulted[1].forEach(result => {
        this.content.push({ text: result.title + ' ' + this.stripHtml(result.text) + '.', style: 'text' });
      });

      this.content.push({
        text: this.whatTheImpact.tabs[2],
        style: 'subtitle',
        // pageBreak: 'before'
      });

      this.resulted[2].forEach(result => {
        this.content.push({ text: result.title + ' ' + this.stripHtml(result.text) + '.', style: 'text' });
      });

      this.docDefinition = {
        header: {
          text: 'b-green | Bewizyu',
          style: 'header'
        },
        footer: function(currentPage, pageCount) {
          return { text: currentPage.toString() + ' / ' + pageCount, style: 'footer' }
        },
        content: this.content,
        styles: {
          header: {
            alignment: 'left',
            margin: [10, 10],
            fontSize: 12,
            color: '#191d3a'
          },
          title: {
            alignment: 'center',
            margin: [10, 10],
            fontSize: 22,
            bold: true,
            color: '#191d3a'
          },
          subtitle: {
            alignment: 'left',
            margin: [0, 10, 0, 10],
            fontSize: 18,
            bold: true,
            color: '#191d3a'
          },
          text: {
            alignment: 'left',
            margin: [0, 0, 0, 0],
            fontSize: 14,
            color: '#191d3a'
          },
          footer: {
            alignment: 'right',
            margin: [10, 10],
            fontSize: 12,
            color: '#191d3a'
          }
        }
      }
    },
    downloadPdf() {
      this.generatePdf();
      // pdfMake.createPdf(this.docDefinition).open();
      pdfMake.createPdf(this.docDefinition).download('b-green-' + this.generateId(16) + '.pdf');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/_var';

.result {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  padding: 20vh 1rem 1rem;

  @media screen and (max-width: $tablet) {
    display: block;
    padding: 6rem 1rem;
  }

  &_subtitle {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 2rem;
  }

  &_menu {
    margin: 2rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    span {
      display: block;
      margin: 0 .5rem;
      padding: 1rem 1.5rem;
      border-radius: 1.75rem;
      background-color: $light-color;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;

      &.active {
        background-color: $tertiary-color;
      }
    }
  }

  &_text {
    max-width: 500px;
    width: 100%;
    margin: 1rem auto 0;
    display: flex;
    align-items: center;
    padding: 0 3rem 1.5rem;
    justify-content: center;
  }
}

.cards {
  display: flex;
  flex-flow: row wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4rem;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  &-enter-active, &-leave-active {
    transition: opacity .2s ease-out;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-to {
    display: none;
  }
}

.card {
  position: relative;
  width: calc((100% - 4rem) / 4);
  margin: 0 .5rem 1rem;
  padding: 4rem 1rem 2rem;
  border-radius: .25rem;
  background-color: $light-color;

  @media screen and (max-width: $tablet) {
    width: calc(50% - 2rem);
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    margin: 0 0 1rem;
  }

  &_title {
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3;
    font-weight: normal;
  }

  &_equiv {
    margin-top: .5rem;
    position: relative;
    z-index: 1;

    div {
      max-width: 300px;
      margin: 0 auto;
      text-align: center;
      line-height: 1.3;
    }
  }

  &_info {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &_percent {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 .5rem;
    border-radius: 1.75rem;
    border: 2px solid $dark-color;
    font-weight: bold;
    color: $dark-color;

    &.positive {
      border-color: green;
      color: green;
    }

    &.negative {
      border-color: red;
      color: red;
    }
  }

  &_modal {
    width: calc((100vw - 4rem) / 4);

    @media screen and (max-width: $tablet) {
      width: 320px;
    }

    @media screen and (max-width: $mobile) {
      width: 260px;
    }
  }
}
</style>
