import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './styles/index.scss';

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');

console.log(
  "%c[b-green]%cv1.0.0%cBewizyu",
  "padding: 4px; background: #18374c; border-radius: 3px 0 0 3px; font-weight: bold; color: #fff;",
  "padding: 4px; background: #51e590; font-weight: bold; color: #18374c;",
  "padding: 4px; background: #18374c; border-radius: 0 3px 3px 0; font-weight: bold; color: #fff;"
);
